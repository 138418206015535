import {
  calculateAge,
  Patients,
  Specialists,
  SpecialistsServices
} from '@seliaco/red-panda'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      appointment: {
        specialist_service: {
          icon_name: null,
          name: null,
          duration: null
        },
        patient: {
          first_name: null,
          last_name: null
        },
        starts_at: null,
        virtual: true
      },
      creating: false,
      form: {
        virtual: true,
        modality: 'ONLINE',
        price: null,
        specialistService: null,
        service: null,
        patient: null,
        specialist: null,
        starts_at: null,
        duration: null,
        reason: null,
        cancellation: {
          policy: 'TWENTY_FOUR_HOURS'
        }
      },
      date: null,
      specialist: null,
      services: null,
      service: null
    }
  },
  created () {
    if (this.$store.getters['auth/specialist']) {
      this.mount()
    } else {
      this.$store.watch(
        () => this.$store.getters['auth/specialist'],
        () => {
          this.mount()
        }
      )
    }
  },
  methods: {
    mount () {
      if (this.$store.getters['auth/isCounselor']) {
        this.preSelectedPatient()
        return
      }

      this.init()
    },
    init () {
      const p1 = Specialists.read({
        columns: `
            user (
              first_name,
              address(*)
            )
          `,
        eq: {
          id: this.$store.getters['auth/specialist'].id
        }
      })

      const pArray = [p1]

      if (this.$route.query.patient_id || this.appointmentStore?.patient?.id) {
        pArray.push(this.promisePatient())
      }

      Promise.all(pArray).then((response) => {
        const specialist = response[0].data[0]
        const patient = response[1]?.data[0] || null

        const data = {
          ...this.$store.getters['auth/specialist'],
          specialistId: this.$store.getters['auth/specialist'].id,
          name: specialist?.user.first_name,
          address: specialist?.user.address
        }
        this.setSpecialist(data)

        if (patient) {
          const patientMapper = {
            id: patient?.id,
            name: `${patient?.first_name} ${patient?.last_name || ''}`,
            gender: this.$translations.gender[patient?.gender],
            age: calculateAge(patient?.birthday),
            avatar: patient?.profile_picture
          }
          this.form.patient = patient.id

          setTimeout(() => {
            if (this.$refs.patientSelector) {
              this.$refs.patientSelector.onSelectPerson(patientMapper)
            }
          })

          this.getServices()
        }
      })
    },
    promisePatient () {
      const id =
        this.$route.query.patient_id || this.appointmentStore.patient.id

      return Patients.read({
        columns: `
        id,
        first_name,
        last_name,
        gender,
        birthday,
        profile_picture
      `,
        eq: { id }
      })
    },
    async preSelectedPatient () {
      const patientId =
        this.$store.getters['patient/get']?.id ||
        this.appointmentStore?.patient?.id

      if (patientId) {
        const response = await this.promisePatient()
        const patient = response.data[0]

        const patientMapper = {
          id: patient?.id,
          name: `${patient?.first_name} ${patient?.last_name || ''}`,
          gender: this.$translations.gender[patient?.gender],
          age: calculateAge(patient?.birthday),
          avatar: patient?.profile_picture
        }
        this.form.patient = patient.id

        setTimeout(() => {
          if (this.$refs.patientSelector) {
            this.$refs.patientSelector.onSelectPerson(patientMapper)
          }
        })
      }
    },
    getServices () {
      SpecialistsServices.getSpecialistServiceByPatient(
        this.form.specialist,
        this.form.patient
      ).then((response) => {
        this.services = response || []
      })
    },
    setPatient (value) {
      this.form.patient = value.id
      this.appointment.patient.first_name = value.name
      if (this.form.specialist) {
        this.getServices()
      }
    },
    setSpecialist (value) {
      this.specialist = value
      this.form.specialist = value.specialistId
      this.form.specialistService = null
      this.form.starts_at = null
      this.appointment.starts_at = null

      if (this.form.specialist && this.form.patient) {
        this.getServices()
      }
    },
    setModality (value) {
      this.form.service = null
      this.service = null
      this.date = null
      this.form.specialistService = null
      this.form.starts_at = null
      this.form.modality = value
      this.appointment.virtual = this.form.virtual = value === 'ONLINE'
    },
    setDate (value) {
      if (!value || (value && !value.code)) {
        this.form.starts_at = null
        return
      }

      this.form.starts_at = value.code
      this.appointment.starts_at = value.code
    },
    setReason (value) {
      this.form.reason = value
    },
    dayChanged (value) {
      if (this.date !== value) {
        this.date = value
        this.form.starts_at = null
      }
    }
  },
  computed: {
    ...mapGetters({
      appointmentStore: 'medical/appointment'
    }),
    getServiceOptions () {
      if (!this.services) {
        return []
      }

      if (this.form.virtual) {
        return this.services
      }
      const temp = []
      for (const item of this.services) {
        if (item.kalendme_in_person_link_id) {
          temp.push(item)
        }
      }
      return temp
    },
    hasInPersonModality () {
      if (!this.services?.length) {
        return false
      }

      for (const item of this.services) {
        if (item.kalendme_in_person_link_id) {
          return true
        }
      }

      return false
    }
  }
}
