<template>
  <div class="patient-form" :class="{ 'patient-form-side-dialog': session }">
    <template v-if="loadingForm">
      <div class="page-segment">
        <div class="page-segment-content">
          <Skeleton
            width="40%"
            height="22px"
            borderRadius="8px"
            class="margin-bottom"
          />
          <Skeleton
            width="100%"
            height="298px"
            borderRadius="8px"
            class="margin-bottom"
          />
        </div>
      </div>
      <div class="page-segment">
        <div class="page-segment-content">
          <Skeleton
            width="40%"
            height="22px"
            borderRadius="8px"
            class="margin-bottom"
          />
          <Skeleton
            width="100%"
            height="198px"
            borderRadius="8px"
            class="margin-bottom"
          />
        </div>
      </div>
      <div class="page-segment">
        <div class="page-segment-content">
          <Skeleton
            width="40%"
            height="22px"
            borderRadius="8px"
            class="margin-bottom"
          />
          <Skeleton
            width="100%"
            height="398px"
            borderRadius="8px"
            class="margin-bottom"
          />
        </div>
      </div>
    </template>

    <template v-else>
      <div class="patient-form-content">
        <!-- personal data -->
        <div class="page-segment">
          <div class="page-segment-content">
            <div
              class="heading-small margin-bottom"
              v-text="$translations.patients.form['personal-info']"
            />
            <DynamicForm
              @form="(e) => handlerForm(e, 'personal')"
              :fields="personal.fields"
              :layout="personal.layout"
              :values="personal.response"
              :lang="language.lang"
            />
          </div>
        </div>

        <!-- contact information -->
        <div class="page-segment">
          <div class="page-segment-content">
            <div
              class="heading-small margin-bottom"
              v-text="$translations.patients.form['contact-info']"
            />
            <DynamicForm
              @form="(e) => handlerForm(e, 'contact')"
              :fields="contact.fields"
              :layout="contact.layout"
              :values="contact.response"
              :lang="language.lang"
            />
          </div>
        </div>

        <!-- place information -->
        <div class="page-segment">
          <div class="page-segment-content">
            <div
              class="heading-small margin-bottom"
              v-text="$translations.patients.form['place-info']"
            />
            <DynamicForm
              @form="(e) => handlerForm(e, 'place')"
              :fields="place.fields"
              :layout="place.layout"
              :values="place.response"
              :lang="language.lang"
            />
          </div>
        </div>

        <!-- medical information -->
        <div class="page-segment patient-margin">
          <div class="page-segment-content">
            <div
              class="heading-small margin-bottom"
              v-text="$translations.patients.form['medical-info']"
            />
            <template v-if="epsGroup && epsGroup.length">
              <DropdownField
                qa="eps"
                :placeholder="$translations.patients.form.eps.label"
                :label="$translations.patients.form.eps.label"
                :options="epsGroup"
                :disabled="
                  form.place &&
                  form.place.placeResidence &&
                  !form.place.placeResidence.values
                "
                v-model="eps"
              />
            </template>
            <template v-else>
              <InputTextField
                qa="eps"
                :placeholder="$translations.patients.form.eps['label-text']"
                :label="$translations.patients.form.eps['label-text']"
                :disabled="
                  form.place &&
                  form.place.placeResidence &&
                  !form.place.placeResidence.values
                "
                v-model="eps"
              />
            </template>
          </div>
        </div>
      </div>

      <div class="patient-form-actions">
        <div class="patient-form-actions-max-width">
          <Button
            type="outline"
            color="purple"
            @clicked="handlerCancel"
            :text="$translations.patients.form.cancel"
          />
          <Button
            :text="$translations.patients.form.update"
            @clicked="handlerUpdate"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {
  DynamicForm,
  Button,
  Meta,
  SpecialistsPatients,
  Skeleton
} from '@seliaco/red-panda'
import { personal, contact, place } from '../configuration/patient-form'

import { DropdownField, InputTextField } from '@seliaco/sea-otter'
import { mapGetters } from 'vuex'

export default {
  name: 'PatientForm',
  components: {
    DynamicForm,
    Button,
    DropdownField,
    InputTextField,
    Skeleton
  },
  props: {
    session: Boolean
  },
  data () {
    return {
      loadingForm: true,
      personal: null,
      contact: null,
      place: null,
      eps: null,
      epsGroup: [],
      countries: null,
      form: {
        personal: null,
        place: null,
        contact: null,
        eps: null
      }
    }
  },
  created () {
    this.formInit()
  },
  methods: {
    async getPatient () {
      if (this.appointment?.created_at || this.$route.params.patientId) {
        let id =
          this.appointment?.patient?.id ||
          this.$route.params.patientId ||
          this.$route.query.patientId

        if (
          this.$route.params.id === '11111111-1111-1111-1111-111111111111' &&
          process.env.VUE_APP_MODE === 'DEV'
        ) {
          id =
            localStorage.getItem('patientIdTest') ||
            'fbd26776-50d0-452f-8745-e2c3b86690a1'
        }

        await this.$store.dispatch('patient/getPatientProfileGeneral', {
          patientId: id,
          specialistId: this.$store.getters['auth/specialist'].id
        })
      }
    },
    async formInit () {
      this.getPatient()
      const response = await Meta.getCountries()
      this.countries = response.map((country) => ({
        ...country,
        label: country.name,
        value: country.name
      }))
      this.place = await place(this.countries)
      this.personal = await personal(this.language.lang)
      this.contact = await contact()

      await this.personalValues()
      await this.contactValues()
      await this.placeValues()
      await this.medicalValues()

      this.loadingForm = false
    },
    personalValues () {
      const name = this.meta?.first_name || this.patient.first_name || ''
      const lastName = this.meta?.last_name || this.patient.last_name || ''
      const gender = this.meta?.gender || this.patient.gender || ''
      const birthday = this.meta?.birthday || this.patient.birthday || null
      const documentType = this.meta?.documentType || ''
      const documentNumber = this.meta?.documentNumber || ''

      const personalValues = {
        first_name: {
          values: name
        },
        last_name: {
          values: lastName
        },
        gender: {
          values: gender
        },
        birthday: {
          values: birthday && new Date(birthday)
        },
        documentType: {
          values: documentType
        },
        documentNumber: {
          values: documentNumber
        }
      }
      this.personal.response = personalValues
    },
    contactValues () {
      const dialCode =
        this.meta?.dial_code || this.patient.dial_code?.split('+')[1]
      const country = this.meta?.dial_country_code || ''
      const phone = this.meta?.phone || ''
      const email = this.meta?.email || ''

      const contactValues = {
        phone: {
          values: {
            country,
            dialCode,
            phone
          }
        },
        email: {
          values: email
        }
      }
      this.contact.response = contactValues
    },
    placeValues () {
      const placeBirth = this.meta?.placeBirth
      const placeResidence = this.meta?.placeResidence || this.patient.address

      const placeValues = {
        placeBirth: {
          values: placeBirth
        },
        placeResidence: {
          values: placeResidence
        }
      }
      this.place.response = placeValues
    },
    medicalValues () {
      this.eps = this.meta?.eps
    },
    async handlerForm (e, section) {
      this.form[section] = { ...e.values }

      if (section === 'place' && e.values?.placeResidence) {
        const country = this.countries.find(
          (c) => e.values?.placeResidence.values === c.name
        )
        if (country?.code) {
          const eps = await Meta.getEpsByCountry(country.code)
          if (eps.length) {
            this.epsGroup = eps.map((eps) => ({
              label: eps,
              value: eps
            }))
          } else {
            this.epsGroup = []
          }
        }
      }
    },
    handlerCancel () {
      if (this.session) {
        this.$parent.$parent.typeLocal = 'EPISODE'
        this.$emit('show-episode')
      } else {
        this.$router.go(-1)
      }
    },
    handlerUpdate () {
      const { personal, contact, place } = this.form
      const age = this.$moment().diff(personal.birthday.values, 'years')

      const body = {
        patient: this.patient.id,
        meta: {
          first_name: personal.first_name.values,
          last_name: personal.last_name.values,
          documentType: personal.documentType.values,
          documentNumber: personal.documentNumber.values,
          birthday: this.$moment(personal.birthday.values).format('YYYY-MM-DD'),
          age,
          gender: personal.gender.values,
          phone: contact.phone.values.phone,
          dial_country_code: contact.phone.values.country,
          dial_code: contact.phone.values.dialCode,
          email: contact.email.values,
          placeBirth: place.placeBirth.values,
          placeResidence: place.placeResidence.values,
          eps: this.eps
        }
      }

      SpecialistsPatients.updatePatient(body)
        .then(() =>
          this.$toast({
            text: this.$translations.success['patient-form'],
            severity: 'success'
          })
        )
        .finally(() => {
          if (this.session) {
            this.getPatient()
            this.$parent.$emit('on-nav-back')
          } else {
            this.$router.go(-1)
          }
        })
    }
  },
  computed: {
    ...mapGetters({
      patient: 'patient/get',
      meta: 'patient/meta',
      loading: 'patient/loading',
      language: 'language',
      appointment: 'medical/appointment'
    })
  }
}
</script>
<style lang="sass" scoped>
.patient
  &-margin
    margin-bottom: 88px
  &-form-actions
    background: white
    padding: 16px
    position: fixed
    bottom: 0
    width: 100%
    left: 0
    z-index: 1000
    &-max-width
      max-width: 480px
      display: flex
      margin: auto
      gap: 10px

  &-form-content
    height: 100%
    overflow: auto

  &-form-side-dialog
    display: grid
    grid-template-rows: 1fr min-content

    .patient-form-content
      overflow: initial

    .patient-form-actions
      position: relative !important

::v-deep .form-field-label
  margin-bottom: 4px !important
</style>
