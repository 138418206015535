<template>
  <div
    class="medical-episode"
    :class="{
      'medical-episode-side-dialog': session,
      'medical-episode-saved': episode.status === 'SAVED',
      'medical-episode-side-dialog-save': session && episode.status === 'SAVED'
    }"
  >
    <template v-if="loading">
      <div class="page-segment" v-for="index in 5" :key="index">
        <div class="page-segment-content">
          <div class="margin-bottom">
            <Skeleton width="100px" height="30px" borderRadius="8px" />
          </div>
          <Skeleton
            class="margin-bottom"
            width="100%"
            height="106px"
            borderRadius="16px"
          />
        </div>
      </div>
    </template>
    <template v-else>
      <template v-if="episode.status === 'SAVED'">
        <div>
          <div class="page-segment" style="order: 1" v-if="hasAppointment">
            <div class="page-segment-content">
              <div
                class="heading-small"
                v-text="$translations['medical-history'].detail.patient"
              />
              <PersonCard
                :image="appointment.patient.profile_picture"
                :title="`${appointment.patient.first_name} ${appointment.patient.last_name}`"
                :first-line="cardFirstLine"
                :middle-line="cardMiddleLine"
              />
            </div>
          </div>
          <div class="page-segment" style="order: 1" v-else>
            <div class="page-segment-content">
              <div class="margin-bottom">
                <Skeleton width="100px" height="30px" borderRadius="8px" />
              </div>
              <Skeleton width="100%" height="92px" borderRadius="16px" />
            </div>
          </div>
        </div>

        <!-- reason -->
        <template v-for="(answer, key) of answers">
          <div
            class="page-segment"
            :style="{ order: answerConfig(key).index + 1 }"
            :key="key"
            v-if="answer.values"
          >
            <div class="page-segment-content">
              <div class="heading-small" v-text="answerConfig(key).text" />

              <template v-if="answer.type === 'DROPDOWN_MULTISELECT'">
                <div
                  class="body"
                  v-for="(item, index) of answer.values"
                  v-text="item.name"
                  :key="index"
                />
              </template>

              <div class="body" v-text="answer.values" v-else />
            </div>
          </div>
        </template>
      </template>

      <template v-else>
        <FlowResolver
          class="medical-episode-flow"
          :flow="flow"
          :router="$router"
          :route="$route"
          :lang="lang"
          @form="patchForm($event)"
          v-if="flow"
        >
          <template v-slot:banner-aux>
            <InfoBanner
              icon="icon-information-off"
              icon-color="red"
              :text="$translations['medical-history'].detail.banner"
              text-color="red-120"
              :text-action="$translations['medical-history'].buttons.update"
              text-action-color="gray-80"
              text-action-class="content-small"
              background="red-5"
              margin="16px 16px 0 16px"
              border="1px solid var(--red-20)"
              @action="openPatientForm"
              v-if="!isComplete && session"
            />
          </template>
        </FlowResolver>
        <div class="medical-episode-actions">
          <div class="medical-episode-actions-max-width">
            <Button
              icon="icon-trash-off"
              color="red"
              @clicked="showDeleteDialog = true"
              :disable="!episode.created_at"
            />
            <Button
              :text="$translations['medical-history'].buttons.save"
              @clicked="showSaveDialog = true"
              :disable="!formValid"
            />
          </div>
        </div>
      </template>
    </template>

    <EpisodeDeleteDialog
      v-if="showDeleteDialog"
      @delete="deleteEpisode"
      @close="showDeleteDialog = false"
    />
    <EpisodeSaveDialog
      v-if="showSaveDialog"
      @save="hadlerStatusEpisode('SAVED', true)"
      @close="showSaveDialog = false"
    />
  </div>
</template>

<script>
import {
  FlowResolver,
  Flow,
  PersonCard,
  Skeleton,
  Button,
  Appointments,
  InfoBanner
} from '@seliaco/red-panda'
import { mapGetters } from 'vuex'
import EpisodeDeleteDialog from '../modal/EpisodeDeleteDialog.vue'
import EpisodeSaveDialog from '../modal/EpisodeSaveDialog.vue'
import { ClinicalEpisode } from '@/types/events'

export default {
  name: 'MedicalEpisode',
  components: {
    FlowResolver,
    PersonCard,
    Skeleton,
    Button,
    EpisodeDeleteDialog,
    EpisodeSaveDialog,
    InfoBanner
  },
  props: {
    session: Boolean
  },
  data () {
    return {
      flowDefault: null,
      flow: null,
      loading: true,
      form: null,
      formValid: false,
      showDeleteDialog: false,
      showSaveDialog: false,
      timeOutTyping: false
    }
  },
  created () {
    this.init()
  },
  destroyed () {
    this.$store.commit('medical/SET_EPISODE', { episode: null })
    this.flow = { ...this.flowDefault }
    if (this.session) {
      clearTimeout(this.timeOutTyping)
    }
  },
  methods: {
    init () {
      if (!this.appointment?.created_at) {
        this.getAppointment()
      }

      this.getFlow()
    },
    async getAppointment () {
      if (!this.episode?.meta?.appointment_id) {
        this.$router.replace({
          path: this.$route.query.back
        })
      }

      const appointment = await Appointments.getAppointmentDetail(
        this.episode.meta.appointment_id
      ).catch(() => {
        if (this.$route.query.back) {
          this.$router.replace({
            path: this.$route.query.back
          })
        } else {
          this.$router.replace({ name: 'Home' })
        }
      })

      await this.$store.commit('medical/SET_APPOINTMENT', { appointment })
      this.loading = false
    },
    async getFlow () {
      this.flowDefault = await Flow.get('MEDICAL_HISTORY', 'spe')
      if (this.session && !this.episode.created_at) {
        const flowDraft = this.history.find((f) => f.status === 'DRAFT')
        if (flowDraft) {
          this.$store.commit('medical/SET_EPISODE', { episode: flowDraft })
        }
      }

      if (this.episode.created_at) {
        const block = {
          ...this.flowDefault.blocks[0],
          answer: this.episode.blocks[0].answer
        }

        this.flow = {
          ...this.flowDefault,
          blocks: [block],
          id: this.episode.id
        }
      } else {
        this.flow = { ...this.flowDefault }
      }

      this.loading = false
    },
    patientAge () {
      const birthday = this.$moment(this.appointment.patient?.birthday).locale(
        this.user.language
      )
      const now = this.$moment(new Date()).locale(this.user.language)
      return now.diff(birthday, 'years')
    },
    deleteEpisode () {
      this.showDeleteDialog = true

      ClinicalEpisode.delete({
        origin: this.session,
        patient_id: this.episode.meta.patient_id,
        specialist_id: this.episode.meta.specialist_id,
        episode_id: this.episode.id
      })

      this.loading = true
      Flow.deleteFlow(this.episode.id, 'spe')
        .then(() => {
          this.flow = { ...this.flowDefault }
          if (this.$route.query.back) {
            this.$router.replace({
              path: this.$route.query.back
            })
          }
        })
        .catch(() => {})
        .finally(() => {
          this.$toast({
            text: this.$translations['medical-history'].toast.delete,
            severity: 'success'
          })
          this.closeInSession(this.session)
        })
    },
    hadlerStatusEpisode (status = 'SAVED', showToast = false) {
      const patientId =
        this.episode?.meta?.patient_id || this.appointment.patient.id
      const specialistId =
        this.episode?.meta?.specialist_id || this.appointment.specialist.id
      const appointmentId =
        this.episode?.meta?.appointment_id || this.appointment.id
      const meta = {
        patient_id: patientId,
        specialist_id: specialistId,
        appointment_id: appointmentId
      }
      let method = 'post'

      // segment event
      ClinicalEpisode.save({
        origin: this.session,
        status,
        ...meta
      })

      // form data
      this.form = {
        ...this.form,
        status,
        meta
      }

      if (this.episode.created_at) {
        method = 'patch'
        this.form = {
          ...this.form,
          id: this.episode.id
        }
      }

      Flow[method](this.form, 'spe')
        .then(() => {
          let text = this.$translations['medical-history'].toast.add

          if (status === 'DRAFT') {
            text = this.$translations['medical-history'].toast.draft
          }

          if (showToast) {
            this.$toast({
              text,
              severity: 'success'
            })
          }
          this.goBackRoute()
        })
        .catch()
        .finally(() => {
          this.closeInSession(showToast)
        })
    },
    patchForm (e) {
      if (this.session) {
        this.handlerTyping()
      }

      const { form, privateBlock } = e

      const objectvalues = Object.values(form.values)

      this.formValid =
        objectvalues.length !==
        objectvalues.filter((i) => !i.values || i.values.length === 0).length
      this.form = {
        ...this.flow,
        blocks: [
          {
            ...privateBlock,
            answer: form.values
          }
        ]
      }
    },
    handlerTyping () {
      clearTimeout(this.timeOutTyping)

      this.timeOutTyping = setTimeout(() => {
        this.hadlerStatusEpisode('DRAFT')
      }, 800)
    },
    async closeInSession (showToast = false) {
      if (this.session) {
        if (showToast) {
          this.$parent.$emit('on-nav-back')
        }
        await this.$store.dispatch('medical/list')
      }
    },
    openPatientForm () {
      if (this.session) {
        this.$parent.$parent.typeLocal = 'PATIENT_DATA'
        this.$emit('show-patient-form')
      }
    },
    goBackRoute () {
      if (!this.session) {
        if (this.$route.query.back) {
          this.$router.replace({
            path: this.$route.query.back
          })
        } else {
          this.$router.go(-1)
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      appointment: 'medical/appointment',
      episode: 'medical/episode',
      history: 'medical/history',
      isComplete: 'patient/isComplete'
    }),
    lang () {
      return this.$store.getters.language.lang
    },
    title () {
      let date = this.$moment().format('DD/MM/YYYY')

      if (this.episode.created_at) {
        date = this.$moment(this.episode.created_at).format('DD/MM/YYYY')
      }

      return this.$translations['medical-history'].episode.replace(
        '{date}',
        date
      )
    },
    cardFirstLine () {
      const gender = this.$translations.gender[this.appointment.patient.gender]
      return `${gender} • ${this.patientAge()} ${
        this.$translations.appointment.create.years
      }`
    },
    cardMiddleLine () {
      if (this.appointment?.patient?.address?.country) {
        return this.appointment?.patient?.address?.country
      }

      return ''
    },
    answers () {
      const answer = this.episode.blocks[0].answer
      return {
        ...answer
      }
    },
    answerConfig () {
      return (key) => {
        const index = this.episode.blocks[0].options.findIndex(
          (elem) => elem?.id === key
        )

        if (!index === -1) {
          return '-'
        }

        return {
          text: this.episode.blocks[0].options[index].label[this.lang],
          index
        }
      }
    },
    hasAppointment () {
      return this.appointment?.patient?.first_name
    }
  }
}
</script>

<style lang="sass" scoped>
.heading-small
  margin-bottom: 19px

.medical-episode
  position: relative
  height: 100%
  background: var(--gray-5)
  &-saved
    display: flex
    flex-direction: column

.medical-episode-actions
  background: white
  padding: 16px
  position: fixed
  bottom: 0
  width: 100%
  left: 0
  z-index: 1000
  &-max-width
    max-width: 480px
    display: grid
    margin: auto
    grid-template-columns: 72px 1fr
    gap: 10px
.medical-episode-flow
  padding-bottom: 80px

.medical-episode-side-dialog-save
  overflow: auto !important
.medical-episode-side-dialog
  ::v-deep .medical-episode-flow
    min-height: inherit !important
    .form-view
      .dynamic-form
        .page-segment
          border-radius: 0px !important
  .medical-episode-actions
    position: absolute !important

.page-segment-content
  .body
    overflow-wrap: break-word
    word-break: break-word
</style>
