<template>
  <div class="datazero-container segment-content">
    <img v-if="image" :src="image" class="image" alt=""/>
    <div v-else :class="icon" class="icon" />
    <div v-if="text" v-text="text" class="text content-title" />
    <div v-if="html" v-html="html" class="html content-title text-gray-80"/>
    <div v-if="buttons.length" class="buttons">
      <Button v-for="(button, index) in buttons" :key="index" class="button"
              :text="button.text"
              :type="button.type"
              @clicked="handleAction(button)"
      />
    </div>
  </div>
</template>

<script>
import { Button } from '@seliaco/red-panda'

export default {
  name: 'DataZeroSimple',
  components: {
    Button
  },
  props: {
    html: String,
    text: String,
    icon: {
      type: String,
      default: 'icon-archive-off'
    },
    image: {
      type: String,
      default: null
    },
    buttons: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    handleAction (action) {
      this.$router.push(action.redirect)
    }
  }
}
</script>

<style lang="sass" scoped>
.datazero-container
  display: flex
  flex-direction: column
  align-items: center
  padding: 40px 20px 20px
  gap: 20px
  .text
    text-align: center
  .image
    width: 100%
    max-width: 120px
  .icon
    display: flex
    color: var(--purple)
    text-align: center
    background: var(--purple-50)
    padding: var(--sm)
    border-radius: var(--border-full)
  .buttons
    gap: 8px
    display: flex
    flex-direction: column
    width: 100%
    .button
      width: 100%

@media (min-width: 1040px)
  .datazero-container
    .image
      max-width: 144px
</style>
