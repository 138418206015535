<template>
  <div
    class="medical-card section-container-white"
    :class="{ 'medical-card-chip': episode.status === 'DRAFT' }"
    @click="$emit('selected')"
  >
    <div class="heading-tag" v-text="text" />
    <Chip
      v-if="episode.status === 'DRAFT'"
      :text="statusTitle"
      color="red"
      size="small"
      classText="small text-red-120 padding-x-xs"
    />
    <div class="icon-display icon-arrow-big-outline-right text-gray-80" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Chip, statusText } from '@seliaco/red-panda'

export default {
  name: 'MedicalCard',
  components: { Chip },
  props: {
    episode: Object
  },
  computed: {
    statusTitle () {
      return statusText(this.episode, this.language.lang)
    },
    ...mapGetters({
      language: 'language'
    }),
    text () {
      const date = this.$moment(this.episode.created_at).format('DD/MM/YYYY')
      return this.$translations['medical-history'].episode.replace(
        '{date}',
        date
      )
    }
  }
}
</script>

<style lang="sass" scoped>
.medical-card
  display: flex
  justify-content: space-between
.medical-card-chip
  display: grid
  grid-template-columns: max-content 1fr 24px
  gap: 8px
  align-items: center
  .heading-tag
    padding: 3.5px 0px
</style>
