<template>
  <DialogContainer
    icon="icon-folder-on"
    icon-color="purple"
    btn-close-color="gray"
    :title="$translations['medical-history'].modal.save.title"
    :confirmation="$translations['medical-history'].modal.save.confirmation"
    :buttons="buttons"
    :custom-icon="true"
    :lang="language.lang"
    :show-button-close="false"
    @action="handlerActions"
    @closeDialog="$emit('close')"
  />
</template>

<script>
import { DialogContainer } from '@seliaco/red-panda'
import { mapGetters } from 'vuex'

export default {
  name: 'EpisodeSaveDialog',
  components: {
    DialogContainer
  },
  data () {
    const buttons = [
      {
        key: 'close',
        text: this.$translations['medical-history'].buttons.cancel,
        color: 'gray',
        type: 'outline'
      },
      {
        key: 'save',
        text: this.$translations['medical-history'].buttons['save-modal'],
        color: 'purple'
      }
    ]

    return {
      buttons
    }
  },
  methods: {
    handlerActions (key) {
      if (key === 'save') {
        this.$emit('save')
      }
      this.$emit('close')
    }
  },
  computed: {
    ...mapGetters({
      language: 'language'
    })
  }
}
</script>
