// PERSONAL_INFORMATION

const firstNameField = {
  type: 'INPUT',
  id: 'first_name',
  label: {
    es: 'Nombre',
    en: 'Name',
    pt: 'Nome'
  },
  placeholder: {
    es: 'Nombre',
    en: 'Name',
    pt: 'Nome'
  },
  style: {
    display: 'grid',
    'grid-column': '1/6'
  }
}

const lastNameField = {
  type: 'INPUT',
  id: 'last_name',
  label: {
    es: 'Apellido',
    en: 'Last name',
    pt: 'Sobrenome'
  },
  placeholder: {
    es: 'Apellido',
    en: 'Last name',
    pt: 'Sobrenome'
  },
  style: {
    display: 'grid',
    'grid-column': '6/11'
  }
}

const documentTypeField = {
  placeholder: {
    es: 'Documento de identificación',
    en: 'Identification document',
    pt: 'Documento de identificação'
  },
  label: {
    es: 'Tipo',
    en: 'Type',
    pt: 'Tipo'
  },
  type: 'DROPDOWN',
  id: 'documentType',
  options: [
    {
      label: 'Cédula Ciudadanía',
      value: 'CC'
    },
    {
      label: 'Cédula Extranjería',
      value: 'CE'
    },
    {
      label: 'Tarjeta de Identidad',
      value: 'TI'
    },
    {
      label: 'Registro civil',
      value: 'RC'
    },
    {
      label: 'DNI',
      value: 'DNI'
    },
    {
      label: 'Pasaporte',
      value: 'PAS'
    }
  ],
  showValue: true,
  style: {
    display: 'grid',
    'grid-column': '1/5'
  },
  hasTopPlaceholder: true,
  subtitleClass: 'form-field-label'
}

const documentNumberField = {
  type: 'INPUTMASK',
  id: 'documentNumber',
  placeholder: {
    es: 'Número',
    en: 'Number',
    pt: 'Número'
  },
  label: {
    es: 'Número',
    en: 'Number',
    pt: 'Número'
  },
  mask: '##############',
  style: {
    display: 'grid',
    'align-items': 'end',
    'grid-column': '5/11'
  }
}

const birthdayField = {
  hasTopLabel: true,
  subtitleClass: 'form-field-label',
  label: {
    es: 'Fecha de nacimiento',
    en: 'Birthday',
    pt: 'Data de nascimento'
  },
  placeholder: {
    day: {
      es: 'Día',
      en: 'Day',
      pt: 'Dia'
    },
    month: {
      es: 'Mes',
      en: 'Month',
      pt: 'Mês'
    },
    year: {
      es: 'Año',
      en: 'Year',
      pt: 'Ano'
    }
  },
  type: 'BIRTHDAY',
  id: 'birthday',
  style: {
    display: 'grid',
    'grid-column': '1/11'
  }
}

const genderField = {
  label: {
    es: 'Género',
    en: 'Gender',
    pt: 'Gênero'
  },
  placeholder: {
    es: 'Género',
    en: 'Gender',
    pt: 'Gênero'
  },
  type: 'DROPDOWN',
  id: 'gender',
  options: [
    {
      value: 'FEMALE',
      label: {
        es: 'Mujer',
        en: 'Female',
        pt: 'Mulher'
      }
    },
    {
      value: 'MALE',
      label: {
        es: 'Hombre',
        en: 'Male',
        pt: 'Homem'
      }
    }
  ],
  style: {
    display: 'grid',
    'grid-column': '1/11',
    'padding-bottom': '0 !important'
  }
}

const personal = (lang) => {
  const genderMapper = {
    ...genderField,
    options: genderField.options.map((option) => ({
      ...option,
      label: option.label[lang]
    }))
  }

  const form = {
    key: 'PERSONAL_INFORMATION',
    layout: {
      rows: 'repeat(4, min-content)',
      columns: 'repeat(10, 1fr)'
    },
    fields: [
      firstNameField,
      lastNameField,
      documentTypeField,
      documentNumberField,
      birthdayField,
      genderMapper
    ]
  }
  return { ...form }
}

// CONTACT_INFORMATION

const phoneField = {
  subtitleClass: 'form-field-label',
  hasTopLabel: true,
  label: {
    es: 'Número de teléfono',
    en: 'Phone number',
    pt: 'Número de telefone'
  },
  type: 'PHONE',
  id: 'phone',
  style: {
    'grid-column': '1/3'
  }
}

const emailField = {
  type: 'EMAIL',
  id: 'email',
  label: {
    es: 'Correo electrónico',
    en: 'E-mail address',
    pt: 'Endereço de e-mail'
  },
  placeholder: {
    es: 'Correo electrónico',
    en: 'E-mail address',
    pt: 'Endereço de e-mail'
  },
  style: {
    'grid-column': '1/3',
    'padding-bottom': '0 !important'
  }
}

const contact = () => {
  const form = {
    key: 'CONTACT_INFORMATION',
    fields: [phoneField, emailField]
  }
  return { ...form }
}

// PLACE_INFORMATION

const placeBirthField = (options) => ({
  label: {
    es: 'Lugar de nacimiento',
    en: 'Place of birth',
    pt: 'Local de nascimento'
  },
  placeholder: {
    es: 'Selecciona ciudad',
    en: 'Select city',
    pt: 'Selecione a cidade'
  },
  type: 'DROPDOWN',
  id: 'placeBirth',
  options,
  hasTopLabel: true,
  subtitleClass: 'form-field-label',
  style: {
    'padding-bottom': '0 !important'
  }
})

const placeResidenceField = (options) => ({
  label: {
    es: 'Lugar de residencia',
    en: 'Place of residence',
    pt: 'Local de residência'
  },
  placeholder: {
    es: 'Selecciona ciudad',
    en: 'Select city',
    pt: 'Selecione a cidade'
  },
  type: 'DROPDOWN',
  id: 'placeResidence',
  options,
  hasTopLabel: true,
  subtitleClass: 'form-field-label',
  style: {
    'padding-bottom': '0 !important'
  }
})
const place = (options) => {
  const form = {
    key: 'PLACE_INFORMATION',
    fields: [placeBirthField(options), placeResidenceField(options)]
  }
  return { ...form }
}

export { personal, contact, place }
