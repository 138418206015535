<template>
  <div class="service-card-wrapper">
    <template v-if="optionsData">
      <ServiceCard
        v-for="(option, index) in optionsData"
        :key="index"
        :service="option"
        :active="isActive(option)"
        @changed="onOptionSelected"
      />
    </template>
    <DataZeroSimple :text="$translations['data-zero'].services" v-else />
  </div>
</template>

<script>
import DataZeroSimple from '@/components/data-zeros/DataZeroSimple'
import ServiceCard from '@/components/cards/ServiceCard'

export default {
  name: 'ServiceSelector',
  components: { ServiceCard, DataZeroSimple },
  props: {
    service: Object,
    optionsData: Array
  },
  data () {
    return {
      selected: null
    }
  },
  methods: {
    onOptionSelected (service) {
      if (this.selected && this.selected.id === service.id) {
        return
      }

      this.selected = service
      this.$emit('changed', service)
    }
  },
  computed: {
    isActive () {
      return (option) => {
        return Boolean(this.selected && this.selected.id === option.id)
      }
    }
  },
  watch: {
    service: {
      handler (val) {
        this.selected = val
      },
      immediate: true
    }
  }
}
</script>

<style lang="sass" scoped>
.service-card-wrapper
  display: flex
  flex-direction: column
  gap: 8px
</style>
