<template>
  <div class="margin-bottom">
    <div class="heading-small margin-bottom" v-text="texts.title" />
    <div class="bg-white">
      <div v-if="selected">
        <PersonCard
          :image="selected.avatar"
          :title="selected.name"
          :first-line="`${selected.gender} • ${selected.age} ${$translations.appointment.create.years}`"
          :middle-line="selected.country"
          :showAction="false"
        />
      </div>
      <div v-else>
        <SearchFilter
          :filters="filters"
          :lang="$store.getters.language.lang"
          @filterBody="handleGetData($event)"
        />
        <Skeleton
          v-if="loading"
          width="100%"
          border-radius="16px"
          height="96px"
        />
        <DataZeroSimple
          :text="texts.dataZero"
          :image="dataZeroImage"
          v-if="persons.length === 0 && !loading"
        />
        <template v-else-if="persons.length && persons.length > 0">
          <div
            class="margin-bottom-xs"
            v-for="(person, index) in persons"
            :key="index"
          >
            <PersonCard
              v-selectable-container="{
                callback: () => onSelectPerson(person)
              }"
              :image="person.avatar"
              :title="person.name"
              :first-line="`${person.gender} • ${person.age} ${$translations.appointment.create.years}`"
              :middle-line="person.country"
              :showAction="false"
            />
          </div>
        </template>
        <LoaderSimple
          class="margin-tip margin-bottom-lg"
          v-if="loadingScroll"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  calculateAge,
  FilterType,
  PersonCard,
  SearchFilter,
  selectableContainer,
  NoConsultants,
  Skeleton,
  userDirectory,
  LoaderSimple
} from '@seliaco/red-panda'
import DataZeroSimple from '@/components/data-zeros/v2/DataZeroSimple'
import { mapGetters } from 'vuex'

export default {
  name: 'UserSelector',
  directives: { selectableContainer },
  components: {
    DataZeroSimple,
    SearchFilter,
    PersonCard,
    Skeleton,
    LoaderSimple
  },
  props: {
    /**
     * One of "PATIENT", "SPECIALIST_PATIENT" or "SPECIALIST"
     */
    type: String,
    session: Boolean,
    specialistId: String
  },
  data () {
    return {
      persons: [],
      selected: null,
      loading: false,
      loadingScroll: false,
      filter: {
        pages: 0,
        page: 1,
        per_page: 10
      },
      texts: {
        title: '',
        select: '',
        dataZero: ''
      },
      elementContent: '',
      dataZeroImage: NoConsultants,
      filters: [FilterType.NAME, FilterType.GENDER],
      actions: {
        back: {
          callback: () => {
            this.$router.go(-1)
          }
        }
      }
    }
  },
  onDestroyed () {
    this.removeListeners()
  },
  mounted () {
    this.mount()
  },
  methods: {
    async mount () {
      switch (this.type) {
        case 'PATIENT':
          this.texts = {
            title: this.$translations.patients.singular,
            select: this.$translations.patients.select,
            dataZero: this.$translations['data-zero'].patients
          }
          break
        case 'SPECIALIST_PATIENT':
          this.texts = {
            title: this.$translations.patients.singular,
            select: this.$translations.patients.select,
            dataZero: this.$translations['data-zero'].patients
          }
          break
        case 'SPECIALIST':
          this.texts = {
            title: this.$translations.specialists.singular,
            select: this.$translations.specialists.select,
            dataZero: this.$translations['data-zero'].specialists
          }
          break
        default:
          console.error(`UserSelector: "No matching case for ${this.type}"`)
      }
      await this.handleGetData()
      await this.createListeners()
    },
    handleGetData (filter, scrolling) {
      if (scrolling) {
        this.loadingScroll = true
      } else {
        this.loading = true
        this.persons = []
        this.filter.page = 1
      }

      if (this.type === 'SPECIALIST') {
        this.filter = {
          ...this.filter,
          type: 'SPECIALIST'
        }
      } else if (this.type === 'SPECIALIST_PATIENT') {
        this.filter = {
          ...this.filter,
          specialist_id: this.$store.getters['auth/specialist'].id
        }
      }

      if (filter?.type) {
        delete this.filter?.name
        delete this.filter?.gender

        const condition = filter.type === FilterType.NAME ? 'name' : 'gender'
        this.filter[condition] = filter.value
      }

      userDirectory
        .list(this.filter)
        .then((response) => {
          this.filter.pages = response.pages
          const dataMapper = response.data.map((person) => {
            return this.mapPerson(person)
          })

          if (scrolling) {
            this.persons = [...this.persons, ...dataMapper]
          } else {
            this.persons = dataMapper
          }
        })
        .finally(() => {
          if (scrolling) {
            this.loadingScroll = false
          } else {
            this.loading = false
          }
        })
    },

    mapPerson (person) {
      let countryCode
      if (person.address?.country) {
        countryCode = person.address.country
      } else {
        const localization = person.localization
        if (localization?.length) {
          countryCode = localization[0].country
        } else if (localization?.country) {
          countryCode = localization?.country
        }
      }

      let country = person.country
      if (countryCode) {
        country =
          this.settings.countries.find(
            (country) => country.value === countryCode
          )[`name_${this.user.language}`] || ''
      }

      let name = `${person.first_name || ''} ${person.last_name || ''}`

      if (person.name) {
        name = person.name
      }

      return {
        id: person.id,
        name,
        gender: this.$translations.gender[person.gender],
        age: calculateAge(person?.birthday?.value || 0),
        avatar: person.profile_picture,
        country,
        specialistId: person.specialistId || '',
        address: person.address || null
      }
    },
    onSelectPerson (person) {
      this.selected = person
      this.filter.page = 1
      this.$emit('changed', person)
    },
    createListeners () {
      this.elementContent = this.$parent.$refs.pageContainer
      this.elementContent.addEventListener('scroll', this.onScroll)
    },
    removeListeners () {
      this.elementContent.removeEventListener('scroll', this.onScroll)
    },
    onScroll () {
      const scrollMaxY =
        this.elementContent.scrollHeight - this.elementContent.clientHeight

      if (
        this.loadingScroll ||
        scrollMaxY < 100 ||
        this.filter.page >= this.filter.pages
      ) {
        return
      }

      if (this.elementContent.scrollTop + 50 > scrollMaxY && !this.selected) {
        this.filter.page++
        this.handleGetData(null, true)
      }
    }
  },
  computed: {
    ...mapGetters({
      settings: 'settings/getSettings',
      user: 'auth/user'
    })
  }
}
</script>
