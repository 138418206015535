<template>
  <div class="medical-history">
    <div class="page-segment" v-if="loading">
      <div class="page-segment-content">
        <div class="margin-bottom">
          <Skeleton width="100px" height="30px" borderRadius="8px" />
        </div>
        <Skeleton
          v-for="index in 7"
          class="margin-bottom"
          :key="index"
          width="100%"
          height="56px"
          borderRadius="16px"
        />
      </div>
    </div>

    <!-- medical history -->
    <div class="page-segment" v-if="!loading && history.length">
      <div class="page-segment-content">
        <div class="heading-small" v-text="title" />
        <div class="medical-history-list">
          <MedicalCard
            v-for="(item, index) in history"
            :key="index"
            :episode="item"
            @selected="handlerMedicalCardAction(item)"
          />
        </div>
        <Button
          type="link"
          :text="this.$translations['medical-history'].buttons.add"
          @clicked="handlerBtn"
          v-if="showAddEpisodeBtn"
        />
      </div>
    </div>

    <div
      :class="datazeroClasses.segment"
      v-if="!loading && history.length === 0"
    >
      <div :class="datazeroClasses.segmentContent">
        <div class="heading-small" v-text="title" />
        <DataZeroSimple
          :text="datazero.text"
          :illustration="datazero.image"
          :buttons="datazero.buttons"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  IlustrationName,
  DataZeroSimple,
  Skeleton,
  Button
} from '@seliaco/red-panda'
import MedicalCard from '@/components/cards/MedicalCard'
import { mapGetters } from 'vuex'
import { ClinicalEpisode } from '@/types/events'

export default {
  name: 'MedicalHistory',
  components: {
    DataZeroSimple,
    MedicalCard,
    Skeleton,
    Button
  },
  props: {
    profile: Boolean,
    session: Boolean
  },
  data () {
    let datazero = {
      image: IlustrationName.checklist_illustration,
      text: this.$translations['data-zero']['medical-history']
    }

    if (!this.profile) {
      datazero = {
        ...datazero,
        buttons: [
          {
            type: 'link',
            text: this.$translations['medical-history'].buttons.add,
            callback: () => this.handlerBtn()
          }
        ]
      }
    }

    return {
      datazero,
      loading: true,
      watcher: null
    }
  },
  created () {
    if (this.profile || this.session) {
      this.getMedicalHistory()
    } else {
      this.watcher = this.$store.watch(
        () => this.appointment,
        () => this.getMedicalHistory()
      )
    }
  },
  beforeDestroy () {
    this?.watcher && this.watcher()
  },
  destroyed () {
    if (!this.session) {
      this.$store.commit('medical/SET_HISTORY', { history: [] })
    }
  },
  methods: {
    async getMedicalHistory () {
      let patientId = null

      if (this.profile) {
        patientId = this.$route.params.id
      }

      await this.$store.dispatch('medical/list', { patientId })

      this.loading = false
    },
    handlerMedicalCardAction (episode) {
      ClinicalEpisode.open({
        origin: this.session,
        patient_id: episode.meta.patient_id,
        specialist_id: episode.meta.specialist_id,
        episode_id: episode.id,
        status: episode.status
      })

      this.$store.commit('medical/SET_EPISODE', { episode })
      if (this.session) {
        this.$emit('show-episode')
      } else {
        this.$router.push({
          name: 'MedicalDetail',
          query: {
            ...this.$route.query,
            back: this.$route.fullPath
          }
        })
      }
    },
    handlerBtn () {
      ClinicalEpisode.create({
        origin: this.session,
        patient_id: this.appointment.patient_id,
        specialist_id: this.appointment.specialist_id
      })

      if (this.session) {
        this.$emit('show-episode')
      } else if (this.appointment?.created_at) {
        this.$router.push({
          name: 'MedicalDetail',
          query: {
            ...this.$route.query,
            back: this.$route.fullPath
          }
        })
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      history: 'medical/history',
      appointment: 'medical/appointment'
    }),
    title () {
      if (this.history.length === 0 && this.appointment.created_at) {
        return this.$translations['medical-history']['title-episode']
      }

      if (this.history.length === 0) {
        return this.$translations['medical-history'].title
      }

      return this.$translations['medical-history'].count.replace(
        '{count}',
        this.history.length
      )
    },
    datazeroClasses () {
      let segment = 'segment'
      let segmentContent = 'segment-content'

      if (this.appointment.created_at) {
        segment = 'page-segment'
        segmentContent = 'page-segment-content'
      }

      return {
        segment,
        segmentContent
      }
    },
    showAddEpisodeBtn () {
      if (this.profile || this.session) {
        return false
      }

      if (this.history) {
        const valid = this.history.find((i) => i.status === 'DRAFT')
        return !valid
      }

      return false
    }
  }
}
</script>

<style lang="sass" scoped>
.medical-history-list
  margin-top: 19px
  display: grid
  gap: 16px
  margin-bottom: 16px
</style>
