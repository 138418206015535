<template>
  <DialogContainer
    icon="icon-trash-on"
    icon-color="red"
    btn-close-color="gray"
    :title="$translations['medical-history'].modal.delete.title"
    :confirmation="$translations['medical-history'].modal.delete.confirmation"
    :buttons="buttons"
    :custom-icon="true"
    :lang="language.lang"
    @action="handlerActions"
    @closeDialog="$emit('close')"
  />
</template>

<script>
import { DialogContainer } from '@seliaco/red-panda'
import { mapGetters } from 'vuex'

export default {
  name: 'EpisodeDeleteDialog',
  components: {
    DialogContainer
  },
  data () {
    const buttons = [
      {
        key: 'delete',
        text: this.$translations['medical-history'].buttons.delete,
        color: 'red'
      }
    ]

    return {
      buttons
    }
  },
  methods: {
    handlerActions (key) {
      if (key === 'delete') {
        this.$emit('delete')
      }
      this.$emit('close')
    }
  },
  computed: {
    ...mapGetters({
      language: 'language'
    })
  }
}
</script>
