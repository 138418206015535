<template>
  <div class="margin-top-lg">
    <div class="icon-archive-off text-purple bg-purple-5 padding-all-sm rounded-full w-min m-auto"></div>
    <div class="text-purple text-center body-small margin-top-xs">
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'DataZeroSimple',
  props: {
    text: String
  }
}
</script>
