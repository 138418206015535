<template>
  <div v-if="userId && appointmentId">
    <selia-video v-if="settings.videoCallVersion === 2 && members"
      :appointment-id="appointmentId"
      :user-id="userId"
      :lang="lang"
      :app-version="$version"
      origin="specialist"
      @completed="goAfterCall"
      @hideChat="contentAux"
      @back="goHome"
      @remote-ready="handleRemoteReady"
      @show-chat="showChat = $event"
    >
      <template v-slot:content>
        <AppointmentCreateSession
          origin="session"
          v-if="content === 'APPOINTMENT'"
        />

        <MedicalHistory
          :session="true"
          @show-episode="contentAux('EPISODE')"
          v-if="content === 'HISTORY'"
        />

        <MedicalEpisode
          :session="true"
          v-if="content === 'EPISODE'"
          @close="contentAux('')"
          @show-patient-form="contentAux('PATIENT_DATA')"
        />

        <PatientForm
          :session="true"
          v-if="content === 'PATIENT_DATA'"
          @close="contentAux('')"
          @show-episode="contentAux('EPISODE')"
        />
      </template>

      <chat
        v-if="members && remote && showChat"
        :allow-navigation="false"
        :show-main-loader="false"
        :members="members"
        hide-header="true"
      />
    </selia-video>

    <template v-else-if="settings.videoCallVersion === 3 && videoCallConfig.sessionId">
      <video-call
        :language="videoCallConfig.language"
        :sessionId="videoCallConfig.sessionId"
        :environment="videoCallConfig.environment"
        :authorization="videoCallConfig.authorization"
        :officeOptions="JSON.stringify(officeOptions)"
        :extrasTitle="officeOptionTitle"
        :extrasTemplateName="extrasTemplateName"
        :origin="'specialist'"
        @leave-session="goAfterCall"
        @leave-lobby="goHome"
        @office-option-clicked="handleOfficeOptionClicked"
        @extras-close="handleExtrasClose"
      >
        <div slot="extra-template">
          <AppointmentCreateSession
            origin="session"
            v-if="content === 'APPOINTMENT'"
          />

          <MedicalHistory
            :session="true"
            @show-episode="contentAux('EPISODE')"
            v-if="content === 'HISTORY'"
          />

          <MedicalEpisode
            :session="true"
            v-if="content === 'EPISODE'"
            @close="contentAux('')"
            @show-patient-form="contentAux('PATIENT_DATA')"
          />

          <PatientForm
            :session="true"
            v-if="content === 'PATIENT_DATA'"
            @close="contentAux('')"
            @show-episode="contentAux('EPISODE')"
          />
        </div>
      </video-call>
    </template>
  </div>
</template>

<script>
import '@seliaco/giant-panda-video-call'
import { SeliaVideo, Appointments } from '@seliaco/red-panda'
import { mapGetters } from 'vuex'
import AppointmentCreateSession from '@/views/agenda/components/AppointmentCreateSession'
import MedicalHistory from '@/views/medical-history/MedicalHistory'
import MedicalEpisode from '@/views/medical-history/components/MedicalEpisode'
import PatientForm from '@/views/patients/components/PatientForm'
import Chat from '@/views/chat/Chat.vue'

export default {
  name: 'Session',
  components: {
    Chat,
    SeliaVideo,
    AppointmentCreateSession,
    MedicalHistory,
    MedicalEpisode,
    PatientForm
  },
  data () {
    return {
      appointmentId: null,
      userId: null,
      lang: null,
      videoMode: null,
      origin: 'specialist',
      remote: null,
      content: null,
      showChat: false,
      members: null,
      videoCallConfig: {
        language: null,
        sessionId: null,
        environment: null,
        authorization: null
      },
      officeOptions: [
        {
          code: 'APPOINTMENT',
          label: this.$translations['video-call-office'].appointment,
          icon: 'APPOINTMENT'
        },
        {
          code: 'HISTORY',
          label: this.$translations['video-call-office']['medica-history'],
          icon: 'TASKS'
        },
        {
          code: 'EPISODE',
          label: this.$translations['video-call-office']['clinical-episode'],
          icon: 'NOTES'
        }
      ],
      officeOptionTitle: null,
      extrasTemplateName: null
    }
  },
  beforeCreate () {
    this.$router.push({ query: { ...this.$route.query, disableBack: 'true' } })
  },
  destroyed () {
    const link = document.getElementById('giant-panda-styles')
    link.parentNode.removeChild(link)
  },
  mounted () {
    if (this.$store.getters['auth/specialist']) {
      this.init()
    } else {
      this.$store.watch(
        () => this.$store.getters['auth/specialist'],
        () => {
          this.init()
        }
      )
    }

    if (this.$store.getters.platform !== 'web') {
      this.handleMobileModal()
    }
  },
  methods: {
    init () {
      this.lang = this.$store.getters.language.lang
      const appointmentId = this.$route.params.id
      this.appointmentId =
        appointmentId === 'demo'
          ? 'eb9bdee9-a31b-46f0-b714-6ff1bdab5d26'
          : appointmentId
      this.userId = this.$store.getters['auth/user'].id

      if (this.settings.videoCallVersion === 3) {
        this.initVersion3()
      }

      this.getAppointmentData()
    },
    async initVersion3 () {
      const link = document.createElement('link')
      link.id = 'giant-panda-styles'
      link.rel = 'stylesheet'
      link.href = 'https://unpkg.com/@seliaco/giant-panda-styles/style.css'
      document.head.appendChild(link)

      const isProd = process.env.VUE_APP_MODE === 'PROD'
      const response = await fetch(
        `https://video${isProd ? '' : '-dev'}.selia.co/api/v1/user/id-sign-in`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ id: this.userId })
        }
      )

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const data = await response.json()

      this.videoCallConfig = {
        language: this.lang,
        sessionId: this.appointmentId,
        environment: isProd ? 'production' : 'staging',
        authorization: data.authorization
      }
    },
    handleRemoteReady (remote) {
      this.remote = remote
      this.$router.replace({
        query: {
          ...this.$route.query,
          patient_id: this.remote.id
        }
      })
    },
    handleMobileModal () {
      this.$globalEvent.$emit('modal/call-recommendations', {
        showDialog: { callRecommendations: { open: true, close: false } }
      })
    },
    contentAux (code) {
      this.content = code
    },
    async getAppointmentData () {
      const appointment = await Appointments.getAppointmentDetail(
        this.appointmentId
      )

      this.$store.commit('medical/SET_APPOINTMENT', { appointment })

      if (appointment?.created_at || this.$route.params.patientId) {
        let patientId = appointment?.patient?.id || this.$route.params.patientId

        if (
          this.$route.params.id === '11111111-1111-1111-1111-111111111111' &&
          process.env.VUE_APP_MODE === 'DEV'
        ) {
          patientId =
            localStorage.getItem('patientIdTest') ||
            'fbd26776-50d0-452f-8745-e2c3b86690a1'
        }

        this.members = [patientId, this.$store.getters['auth/user'].id]

        await this.$store.dispatch('medical/list')
        await this.$store.dispatch('patient/getPatientProfileGeneral', {
          patientId,
          specialistId: this.$store.getters['auth/specialist'].id
        })
      }
    },
    goAfterCall (e) {
      if (e?.detail?.reason === 'ERROR') {
        return this.$router.push('/')
      }

      this.$router.push({
        name: 'AppointmentDetail',
        query: { origin: 'aftercall' }
      })
    },
    goHome () {
      this.$router.push({ name: 'Home' })
    },
    handleOfficeOptionClicked (option) {
      this.officeOptionTitle = option.detail.label
      this.content = option.detail.code
      this.extrasTemplateName = 'CUSTOM'
    },
    handleExtrasClose () {
      this.officeOptionTitle = null
      this.content = null
      this.extrasTemplateName = null
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      settings: 'settings/getSettings'
    })
  },
  watch: {
    $route (to) {
      if (!to.query.disableBack) {
        this.$router.push({ query: { ...to.query, disableBack: 'true' } })
      }
    }
  }
}
</script>
