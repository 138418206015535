<template>
  <div class="appointment-create-session" ref="pageContainer">
    <!-- patient specialist selector as a counseler-->
    <div class="page-segment" v-if="$store.getters['auth/isCounselor']">
      <div class="page-segment-content">
        <!-- patient selector -->
        <UserSelector
          @changed="setPatient"
          type="PATIENT"
          ref="patientSelector"
        />
        <!-- specialists selector -->
        <UserSelector @changed="setSpecialist" type="SPECIALIST" />
      </div>
    </div>

    <!-- patient specialist selector -->
    <div class="page-segment" v-else-if="form.specialist">
      <div class="page-segment-content">
        <UserSelector
          :session="true"
          :specialistId="form.specialist"
          @changed="setPatient"
          type="SPECIALIST_PATIENT"
          ref="patientSelector"
        />
      </div>
    </div>

    <!-- service selector -->
    <div class="page-segment" v-if="form.patient && form.specialist">
      <div class="page-segment-content">
        <div class="heading-small" v-text="$translations.form.service" />
        <template v-if="services">
          <ModalitySelector
            @changed="setModality"
            :showPresentialOption="hasInPersonModality"
          />

          <template v-if="!form.virtual">
            <div
              class="title"
              v-text="
                `${$translations.appointment.address['consulting-room']} ${specialist.name}`
              "
            />
            <template
              v-if="specialist.address && specialist.address.full_address"
            >
              <span class="icon-pin-on" />
              <span
                class="padding-left-xs"
                v-text="specialist.address.full_address"
              />
            </template>
          </template>

          <ServiceSelector
            :service="service"
            :options-data="getServiceOptions"
            @changed="setService"
          />
        </template>
        <template v-else>
          <div class="margin-y">
            <Skeleton
              class="margin-bottom"
              width="103px"
              height="38px"
              borderRadius="8px"
            />
          </div>
          <Skeleton
            class="margin-bottom-xs"
            v-for="index in 3"
            :key="index"
            width="100%"
            height="102px"
            borderRadius="8px"
          />
        </template>
      </div>
    </div>

    <!-- date -->
    <div class="page-segment" v-if="form.specialistService">
      <div class="page-segment-content">
        <AppointmentDateSelector
          :specialist-id="form.specialist"
          :service-id="form.specialistService"
          :modality="form.modality"
          @changed="setDate"
          @dayChanged="dayChanged"
        />
      </div>
    </div>

    <!-- reason -->
    <div class="page-segment" v-if="form.starts_at">
      <div class="page-segment-content">
        <div
          class="heading-small margin-bottom"
          v-text="$translations.appointment.detail.reason"
        />
        <TextareaField
          v-model="form.reason"
          className="text-area margin-top-zero"
          :label="$translations.form['textarea-placeholder']"
          :value="form.reason"
          @change="setReason"
        />
      </div>
    </div>

    <!-- summary -->
    <div class="page-segment" v-if="form.starts_at">
      <div class="page-segment-content">
        <div
          class="heading-small margin-bottom"
          v-text="$translations.form.summary"
        />
        <SpecialistsAppointmentCard :appointment="appointment" />
      </div>
    </div>

    <!-- payment -->
    <div class="page-segment" v-if="form.starts_at">
      <div class="page-segment-content">
        <div
          class="heading-small margin-bottom"
          v-text="$translations.form.payment"
        />
        <!-- origin view session -->
        <div class="margin-y">
          <List
            :options="listOptions"
            type="SELECT"
            @clicked="handlePaymentMethodSelected"
            ref="paymentMethodsList"
          />
        </div>
        <InfoBanner
          v-if="infoBannerText"
          icon="icon-warning-circle-off"
          icon-color="gray-50"
          :text="infoBannerText"
        />
      </div>
    </div>

    <div class="submit-button">
      <Button
        @clicked="appointmentCreate"
        :text="$translations.appointment.create['submit-call']"
        :loading="creating"
        :disable="!isValid"
      />
    </div>
  </div>
</template>

<script>
import SpecialistsAppointmentCard from '@/components/cards/SpecialistsAppointmentCard'
import AppointmentDateSelector from '@/components/fields/AppointmentDateSelector'
import ServiceSelector from '@/components/fields/ServiceSelector'
import ModalitySelector from '@/components/fields/ModalitySelector'
import UserSelector from '@/components/fields/UserSelector'
import { TextareaField } from '@seliaco/sea-otter'
import {
  Appointments,
  Button,
  List,
  InfoBanner,
  Skeleton
} from '@seliaco/red-panda'
import AppointmentCreateMixin from '@/mixins/appointment-create-mixin'

export default {
  name: 'AppointmentCreateSession',
  components: {
    SpecialistsAppointmentCard,
    AppointmentDateSelector,
    ServiceSelector,
    ModalitySelector,
    UserSelector,
    TextareaField,
    Button,
    List,
    InfoBanner,
    Skeleton
  },
  mixins: [AppointmentCreateMixin],
  data () {
    return {
      infoBannerText: null,
      consumePackage: false,
      consumeBenefit: false,
      packages: false,
      benefits: false,
      optionSelected: null
    }
  },
  methods: {
    appointmentCreate () {
      if (!this.isValid) {
        return
      }

      this.creating = true

      const cancellation = {
        policy: null
      }

      if (this.form.is_automatic_cancellation_enabled) {
        if (this.optionSelected === '24_HOURS') {
          cancellation.policy = 'TWENTY_FOUR_HOURS'
        } else if (this.optionSelected === '24_HOURS_BEFORE') {
          cancellation.policy = 'TWENTY_FOUR_HOURS_BEFORE_APPOINTMENT'
        }
      }

      const body = {
        reason: this.form.reason,
        internal_note: this.form.internal_note,
        status: 'PENDING',
        origin: 'SPECIALIST',
        specialist_service: this.form.service,
        specialist: this.form.specialist,
        patient: this.form.patient,
        virtual: this.form.virtual,
        starts_at: this.form.starts_at,
        user_specialist: this.$store.getters['auth/user'].id,
        created_by: this.$store.getters['auth/specialist'].id,
        cancellation,
        consume_package: this.consumePackage,
        consume_benefit: this.consumeBenefit
      }

      Appointments.createSpe(body)
        .then(() => {
          this.$toast({
            severity: 'success',
            text: this.$translations.success['appointment-created']
          })
          this.$globalEvent.$emit('close/side-dialog')
        })
        .catch((e) => {
          this.$toast({
            severity: 'error',
            text: e.message || this.$translations.error.default
          })
        })
        .finally(() => {
          this.creating = false
        })
    },
    setService (value) {
      this.packages = this.benefits = false
      this.form.specialistService = value.id
      this.service = value.service
      this.form.service = value.service.specialist_service_id
      this.form.price = value.service.price
      this.form.duration = value.service.duration
      this.optionSelected = ''
      this.appointment.specialist_service.icon_name = value.service.icon_name
      this.appointment.specialist_service.name = value.service.name
      this.appointment.specialist_service.duration = value.service.duration

      if (value.pricing.discounts.length) {
        value.pricing.discounts.forEach((i) => {
          if (i.slug === 'PACKAGE' && i.meta.remaining_uses > 0) {
            this.packages = true
          }
          if (i.slug === 'BENEFIT' && i.meta.percentage_value === 100) {
            this.benefits = true
          }
        })
      }

      this.setDate()
    },
    handlePaymentMethodSelected (paymentMethod) {
      this.optionSelected = paymentMethod.type
      switch (paymentMethod?.type) {
        case '24_HOURS':
          this.form.is_automatic_cancellation_enabled = true
          this.infoBannerText =
            this.$translations.appointment.create['payment-expired-on']
          break
        case 'IN_APPOINTMENT':
          this.form.is_automatic_cancellation_enabled = false
          this.infoBannerText =
            this.$translations.appointment.create['payment-expired-off']
          break
        case 'PACKAGE':
          this.consumePackage = true
          this.infoBannerText =
            this.$translations.appointment.create['payment-package-reminder']
          break
        case '24_HOURS_BEFORE':
          this.form.is_automatic_cancellation_enabled = true
          this.infoBannerText =
            this.$translations.appointment.create['payment-expired-before-on']
          break
        case 'BENEFIT':
          this.consumeBenefit = true
          this.infoBannerText =
            this.$translations.appointment.create['payment-benefit-on']
          break
      }
    }
  },
  computed: {
    isValid () {
      return Boolean(
        this.form.duration &&
          this.form.service &&
          this.form.specialist &&
          this.form.patient &&
          this.form.starts_at &&
          this.optionSelected
      )
    },
    listOptions () {
      const dateTime = this.$luxon.DateTime.now().plus({ hours: 24 })
      const dateStart = this.$luxon.DateTime.fromJSDate(this.form.starts_at)
      const isBefore24Hours = dateTime < dateStart

      let options = [
        {
          text: this.$translations.appointment.create['payment-appointment'],
          type: 'IN_APPOINTMENT'
        }
      ]

      if (isBefore24Hours) {
        options = [
          {
            text: this.$translations.appointment.create['payment-expired'],
            type: '24_HOURS'
          },

          {
            text: this.$translations.appointment.create[
              'payment-expired-before'
            ],
            type: '24_HOURS_BEFORE'
          },
          ...options
        ]
      }

      if (this.packages) {
        options = [
          ...options,
          {
            text: this.$translations.appointment.create['payment-package'],
            type: 'PACKAGE'
          }
        ]
      }

      if (this.benefits) {
        options = [
          ...options,
          {
            text: this.$translations.appointment.create['payment-benefit'],
            type: 'BENEFIT'
          }
        ]
      }

      return options
    }
  }
}
</script>

<style lang="sass" scoped>

.appointment-create-session
  padding-bottom: 16px
  overflow: auto !important
.submit-button
  padding: 16px
</style>
